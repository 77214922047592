import { createGlobalStyle } from 'styled-components';
import { rem, getContrast, lighten } from 'polished';
import { breakpoint, headingSizes } from '../../theme';

export default createGlobalStyle`
    *, *::before, *::after {
      box-sizing: inherit;
    }

    html {
      box-sizing: border-box;
      font-size: ${({ theme }) => theme.typography.global.fontSize};
    }

    body {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-rendering: ${({ theme }) => theme.typography.global.rendering};
      color: ${({ theme }) => theme.palette.text.primary};
      background-color: ${({ theme }) => theme.palette.background.body};
      font-family: ${({ theme }) => theme.typography.fonts.primary};
      line-height: ${({ theme }) => theme.typography.global.lineHeight};
      font-size: ${({ theme }) => theme.typography.paragraph.fontSize};
    }

    a {
      color: ${({ theme }) => theme.typography.anchor.default};
      text-decoration: ${({ theme }) => theme.typography.anchor.decoration};

      &:hover {
        color: ${({ theme }) => theme.typography.anchor.hover};
      }
    }

   p {
      line-height: ${({ theme }) => theme.typography.paragraph.lineHeight};
      margin-top: 0;
      margin-bottom: ${({ theme }) => theme.typography.paragraph.marginBottom};
      font-weight: ${({ theme }) => theme.typography.weights.primary.normal};
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-transform: ${({ theme }) => theme.typography.heading.textTransform};
      line-height: ${({ theme }) => theme.typography.heading.lineHeight};
      margin-top: 0;
      margin-bottom: ${({ theme }) => theme.typography.heading.marginBottom};
      font-weight: ${({ theme }) => theme.typography.weights.heading.bold};
      letter-spacing: ${({ theme }) => theme.typography.heading.letterSpacing};
    }

    ${({ theme }) => headingSizes(theme.typography.heading.headingSizes)}

    table {
      th {
        text-align: left;
      }
    }

    img {
      max-width: 100%;
    }

    figure {
      margin: 0;
    }

    hr {
      border-color: ${({ theme }) => theme.global.border.color};
      border-width: ${({ theme }) => theme.global.border.width};
      border-style: ${({ theme }) => theme.global.border.style};
      margin-top: ${rem(25)};
      margin-top: ${rem(25)};
    }

    label {
      display: block;
      line-height: ${({ theme }) => theme.typography.paragraph.lineHeight};
      margin: 0;
      font-weight: ${({ theme }) => theme.typography.weights.primary.bold};
      font-size: ${({ theme }) => theme.typography.paragraph.fontSize};
    }

    [type='text'],
    [type='password'],
    [type='date'],
    [type='datetime'],
    [type='datetime-local'],
    [type='month'],
    [type='week'],
    [type='email'],
    [type='number'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='url'],
    [type='color'],
    textarea {
      display: block;
      width: 100%;
      margin: 0 0 1rem;
      padding: 0.5rem;
      border: 1px solid #cacaca;
      border-radius: 0;
      background-color: #fefefe;
      box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
      font-family: inherit;
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.5;
      color: #0a0a0a;
      transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
      appearance: none;
    }

    textarea {
      min-height: ${rem(50)};
    }

    select {
      width: 100%;
      height: 2.4375rem;
      margin: 0 0 1rem;
      padding: 0.5rem;
      appearance: none;
      border: 1px solid #cacaca;
      border-radius: 0;
      background-color: #fefefe;
      font-family: inherit;
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.5;
      color: #0a0a0a;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
      background-origin: content-box;
      background-position: right -1rem center;
      background-repeat: no-repeat;
      background-size: 9px 6px;
      padding-right: 1.5rem;
      transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    }

    select:not([multiple]) {
      padding-top: 0;
      padding-bottom: 0;
    }

    button,
    [type="button"],
    [type="reset"],
    [type="submit"] {
      appearance: none;
      outline: 0;
    }

    input[type="button"],
    input[type="reset"],
    input[type="submit"] {
      background-color: ${({ theme }) => theme.palette.colors.primary};
      border: none;
      border-radius: ${({ theme }) => theme.components.button.radius};
      padding: ${({ theme }) => theme.components.button.padding};
      letter-spacing: ${({ theme }) => theme.typography.button.letterSpacing};
      text-transform: ${({ theme }) => theme.typography.button.textTransform};
      font-family: ${({ theme }) => theme.typography.fonts.button};
      font-weight: ${({ theme }) => theme.typography.weights.button.normal};
      margin-bottom: ${({ theme }) => theme.typography.paragraph.marginBottom};
      transition: all ${({ theme }) =>
        `${theme.global.transition.duration} ${theme.global.transition.timing}`};

      cursor: pointer;
      user-select: none;
      color: ${({ theme }) =>
        getContrast(theme.palette.colors.primary, '#fff') > 3
          ? '#fff'
          : '#000'};

      &:hover {
        background-color: ${({ theme }) =>
          lighten(0.1, theme.palette.colors.primary)};
        color: ${({ theme }) =>
          getContrast(theme.palette.colors.primary, '#fff') > 3
            ? '#fff'
            : '#000'};
      }

      &.expanded {
        width: 100%;
      }
    }

    code {
      border-color: ${({ theme }) => theme.global.border.color};
      border-width: ${({ theme }) => theme.global.border.width};
      border-style: ${({ theme }) => theme.global.border.style};
      background-color: ${({ theme }) => theme.palette.colors.greyLight};
      display: inline;
      max-width: 100%;
      word-wrap: break-word;
      padding: 0.125rem 0.3125rem 0.0625rem;
    }

    pre {
      position: relative;
      margin: 0;
      overflow: auto;
      padding: 0;

      code {
        display: block;
        overflow-x: auto;
        padding: 1rem;
        padding-right: 4rem;
        background-color: ${({ theme }) => theme.palette.colors.greyLight};
        margin-bottom: 1.5rem;
      }
    }

    blockquote {
      position: relative;
      border: 0;
      font-size: ${rem(22)};
      width: 100%;
      text-align: center;
      margin: auto;
      font-family: ${(props) => props.theme.typography.fonts.alt};
      font-weight: ${(props) => props.theme.typography.weights.alt.normal};
      margin-top: ${rem(50)};
      margin-bottom: ${rem(50)};
      padding: ${rem(50)} 0 0 0;
    
      ${breakpoint('mediumlarge')} {
        padding-top: 0;
        padding-left: ${rem(50)};
        font-size: ${rem(23)};
      }

      ${breakpoint('large')} {
        font-size: ${rem(26)};
      }

      &:before {
        content: '”';
        display: block;
        line-height: 0;
        margin-top: 1rem;
        color: ${(props) => props.theme.palette.colors.primary};
        font-family: ${(props) => props.theme.typography.fonts.alt};
        font-weight: ${(props) => props.theme.typography.weights.alt.normal};
        font-size: ${rem(120)};

        ${breakpoint('mediumlarge')} {
          position: absolute;
          right: 100%;
          margin-top: 0;
          margin-right: ${rem(-52)};
          top: ${rem(45)};
        }
      }
    }
  `;
